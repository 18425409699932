<template>
  <v-row>
    <v-col cols="12">
      <div id="invoiceAttachment" style="break-after: auto">
        <div class="black--text mt-3">
          <table class="Full-Width" >
            <tbody>
              <tr>
                <td>
                  <table class="Full-Width">
                    <tbody >
                      <tr>
                        <td>
                          <h2 class="Margin">
                            Momentum Company Limited Saudi Arabia
                          </h2>
                          <h2
                            class="Margin Arabic-Text"
                            style="font-size: 16px; font-weight: 700"
                          >
                            شركة مومينتم السعودية المحدودة شركة شخص واحد
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Khalid Bin Al Walid St Building 7953 Sharafiyah Dist
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Jeddah 22234-4959 kingdom of Saudi Arabia</td>
                      </tr>
                      <tr>
                        <td class="Arabic-Text Margin">
                          لشارع خالد بن الوليد المبنى رقم 7953 حي الشرفية
                        </td>
                      </tr>
                      <tr>
                        <td class="Arabic-Text Margin">
                          جدة 22234-4959 المملكة العربية السعودية
                        </td>
                      </tr>
                      <tr>
                        <td>Tel: +966591257663</td>
                      </tr>
                      <tr>
                        <td>E:info@momentumlogistics.com</td>
                      </tr>
                      <tr width="33%" align="center">
                        <img
                          alt="Brand Logo"
                          class="Brand-Logo"
                          src="@/assets/images/brand-logo.png"
                        />
                        <p class="text-h6 font-weight-medium ml-3"></p>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <v-divider></v-divider>
                </td>
              </tr>

              <tr>
                <td class="text-center">
                  <p class="text-h5 font-weight-bold my-4">Tax Credit Note</p>
                </td>
              </tr>

              <tr>
                <td>
                  <table class="Full-Width">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <v-row>
                            <v-col cols="12" lg="7">
                              <table>
                                <tbody>
                                  <tr>
                                    <th class="text-left Vertical-Align">
                                      Customer
                                    </th>
                                    <th class="px-4 Vertical-Align">:</th>
                                    <td
                                      class="text-left"
                                      v-if="
                                        'company_details' in invoiceDetails &&
                                        invoiceDetails.company_details
                                      "
                                    >
                                      <p class="pa-0 ma-0">
                                        {{
                                          invoiceDetails.company_details
                                            .company_code
                                        }}
                                      </p>
                                      <p class="pa-0 ma-0">
                                        {{
                                          invoiceDetails.company_details
                                            .company_name
                                        }}
                                      </p>
                                      <p class="pa-0 ma-0">
                                        {{
                                          invoiceDetails.company_details
                                            .address_detail.address
                                        }}
                                      </p>
                                      <p class="pa-0 ma-0">
                                        {{
                                          invoiceDetails.company_details
                                            .full_company_contact_number
                                        }}
                                      </p>
                                    </td>

                                    <td v-else>
                                      <p
                                        class="pa-0 ma-0"
                                        v-if="invoiceDetails.company_code"
                                      >
                                        {{ invoiceDetails.company_code }}
                                      </p>
                                      <p
                                        class="pa-0 ma-0"
                                        v-if="invoiceDetails.company_name"
                                      >
                                        {{ invoiceDetails.company_name }}
                                      </p>
                                      <p
                                        class="pa-0 ma-0"
                                        v-if="invoiceDetails.company_address"
                                      >
                                        {{ invoiceDetails.company_address }}
                                      </p>
                                      <p
                                        class="pa-0 ma-0"
                                        v-if="
                                          invoiceDetails.company_contact_number
                                        "
                                      >
                                        {{
                                          invoiceDetails.company_contact_number
                                        }}
                                      </p>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colspan="3">
                                      <br />
                                    </td>
                                  </tr>

                                  <tr>
                                    <th class="text-left">
                                      <b>Tax Regn No.</b>
                                    </th>
                                    <th class="px-4">:</th>
                                    <td
                                      class="text-left"
                                      v-if="
                                        invoiceDetails.vat_registration_number
                                      "
                                    >
                                      <b>{{
                                        invoiceDetails.vat_registration_number
                                      }}</b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-col>

                            <v-col cols="12" lg="5">
                              <table>
                                <tbody>
                                  <tr v-if="invoiceDetails.invoice_no">
                                    <th class="text-left">Invoice No</th>
                                    <th class="px-4">:</th>
                                    <td>{{ invoiceDetails.invoice_no }}</td>
                                  </tr>

                                  <tr v-if="invoiceDetails.invoice_date">
                                    <th class="text-left">Invoice Date</th>
                                    <th class="px-4">:</th>
                                    <td>
                                      {{
                                        invoiceDetails.invoice_date
                                          | formatDate(false)
                                      }}
                                    </td>
                                  </tr>
                                  <tr v-if="invoiceDetails.invoice_type">
                                    <th class="text-left">Invoice Type</th>
                                    <th class="px-4">:</th>
                                    <td>
                                      {{ invoiceDetails.invoice_type }}
                                    </td>
                                  </tr>
                                  <tr v-if="invoiceDetails.boe">
                                    <th class="text-left">BOE</th>
                                    <th class="px-4">:</th>
                                    <td>
                                      {{ invoiceDetails.boe }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr v-if="invoiceDetails.departure_country">
                        <!-- <th class="text-left">
                            {{
                              ["Local"].indexOf(invoiceDetails.invoice_type) >= 0
                                ? "Ship From"
                                : "Source Point"
                            }}
                          </th> -->
                        <!-- <th class="px-4">:</th> -->
                        <td>
                          <!-- {{
                              invoiceDetails.delivery_advice_details[0]
                                .source_point
                            }} -->
                        </td>
                      </tr>

                      <tr v-if="invoiceDetails.mid_country">
                        <!-- <th class="text-left">
                            {{
                              ["Local"].indexOf(invoiceDetails.invoice_type) >= 0
                                ? "Ship To"
                                : "Mid Point"
                            }}
                          </th> -->
                        <!-- <th class="px-4">:</th> -->

                        <td>
                          <!-- {{
                              invoiceDetails.delivery_advice_details[0].mid_point
                            }} -->
                        </td>
                      </tr>
                      <tr v-if="invoiceDetails.destination_country">
                        <!-- <th class="text-left">Destination Point</th> -->
                        <!-- <th class="px-4">:</th> -->

                        <td>
                          <!-- {{
                              invoiceDetails.delivery_advice_details[0]
                                .destination_point
                            }} -->
                        </td>
                      </tr>

                      <tr v-if="invoiceDetails.branch">
                        <th class="text-left">Service Location</th>
                        <th class="px-4">:</th>
                        <td>{{ invoiceDetails.branch }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <table class="Full-Width Invoice-Table">
                    <thead>
                      <tr>
                        <th class="text-left">SR No</th>
                        <th class="text-left">Description</th>
                        <th class="text-right">Initial Invoice Amt</th>
                        <th class="text-right">Revised Sale Value</th>

                        <th class="text-right">Net Diff.</th>

                        <th class="text-right">VAT %</th>
                        <th class="text-right">VAT on Net</th>
                        <th class="text-right">
                          Gross Amount ({{ $globals.currency }})
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(charge, i) in invoiceDetails.invoice_charges"
                        :key="i"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>{{ charge.da_no }} / {{ charge.charge_title }}</td>

                        <td class="text-right">
                          {{
                            charge.initial_invoice_amount | formatNumber(true)
                          }}
                        </td>
                        <td class="text-right">
                          {{ charge.net_diff }}
                        </td>
                        <td class="text-right">
                          {{
                            charge?.revised_sale_amount?.charge_credit_amount
                          }}
                        </td>

                        <td class="text-right">
                          {{ `${charge.tax_percentage} %` }}
                        </td>
                        <td class="text-right">
                          {{ charge.tax_amount | formatNumber(true) }}
                        </td>
                        <td class="text-right">
                          {{ charge.gross_total | formatNumber(true) }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2" class="font-weight-bold">
                          Grand Total ({{ $globals.currency }}) :
                        </td>

                        <td class="font-weight-bold text-right"></td>
                        <td class="font-weight-bold text-right"></td>
                        <td class="font-weight-bold text-right"></td>
                        <td class="font-weight-bold text-right"></td>
                        <td class="font-weight-bold text-right"></td>
                        <td class="font-weight-bold text-right">
                          {{
                            invoiceDetails.grand_total_gross_amount
                              | formatNumber(true)
                          }}
                        </td>
                      </tr>

                      <tr>
                        <!-- <td colspan="8">
                            <span class="text-subtitle-1 font-weight-bold">
                              {{ $globals.currency }}
                            </span>
                            <span class="text-subtitle-1">
                              {{ invoiceDetails.total_gross_amount_words }}
                            </span>
                          </td> -->
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <th>Remarks :</th>
                        <td>{{ invoiceDetails.remarks }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <table class="Full-Width">
                    <tbody>
                      <tr>
                        <!-- <td>
                            <span class="text-subtitle-1 font-weight-bold">
                              Processed By :
                            </span>
                          </td>
  
                          <td>
                            <span class="text-subtitle-1 font-weight-bold">
                              Approved By :
                            </span>
                          </td> -->
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <!-- <td>
                    <span class="text-subtitle-1">
                      Discrepancy, if any, noted should be alerted to us within 14
                      days of receipt of the invoice or else the invoice would be
                      considered as accepted.
                    </span>
                  </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    invoiceDetails: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
#invoiceAttachment {
  font-family: "Roboto", sans-serif !important;
}

.Invoice-Table,
.Invoice-Table th,
.Invoice-Table td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
  margin-top: 12px;
}

.Border-Top-Class {
  border-top: 1px solid black;
}

.Border-Bottom-Class {
  border-bottom: 1px solid black;
}

.Border-Left-Class {
  border-left: 1px solid black;
}

.Border-Right-Class {
  border-right: 1px solid black;
}

.Brand-Logo {
  width: 200px;
}

.d-flex {
  display: flex !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.Vertical-Align {
  vertical-align: top;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.px-3 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.px-4 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-10 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.text-h5 {
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}

.text-h6 {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Roboto", sans-serif !important;
}

.text-subtitle-1 {
  font-size: 1rem !important;
  font-weight: normal;
  line-height: 1.75rem;
  letter-spacing: 0.009375em !important;
  font-family: "Roboto", sans-serif !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.Check-Box {
  margin-top: 0px;
  padding-top: 0px;
}

.Check-Box
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-input--selection-controls__ripple {
  height: 25px;
  width: 25;
  left: 0px;
  top: calc(50% - 13px);
  margin: 0px;
}
</style>
