<template>
  <v-dialog persistent max-width="92%" v-model="customerInvoiceFormDialog">
    <v-card>
      <v-card-title class="secondary white--text px-4 py-2">
        <span>{{ isEditing ? "Edit" : "Add" }} Charge</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="customerInvoiceFormDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-form ref="customerInvoiceChargeForm" v-model="chargesValid">
          <v-row class="ma-0">
            <v-alert v-if="showAlert" type="info" color="red">
              Note:<br />
              1. Please note that the DA and Legs will move to 'Ready for
              Invoice' status, and the credit amount will be the sum of the DA
              amount and its charges.<br />
              2. Be aware that DAInvoiceCharges associated with that DA will be
              deleted. <br />3. Customer invoice amounts will be updated
              accordingly.
            </v-alert>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="4" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="text"
                label="Charge Title."
                readonly
                :clearable="false"
                required
                :rules="[(val) => !!val || 'Charge Title Required!']"
                v-model="daInvoiceCharge.charge_title"
                :error-messages="formErrors.charge_title"
                @input="delete formErrors.charge_title"
              />
            </v-col>
            <v-col cols="4" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="text"
                label="Description *"
                :readonly="
                  daInvoiceCharge.charge_type == 'Freight' || isReadOnly
                "
                :clearable="false"
                required
                :rules="[(val) => !!val || 'Charge Description is Required!']"
                v-model="daInvoiceCharge.description"
                :error-messages="formErrors.description"
                @input="delete formErrors.description"
              />
            </v-col>
            <v-col cols="4" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="text"
                label="Revenue GL No."
                readonly
                :clearable="false"
                required
                :rules="[(val) => !!val || 'Revenue GL No is Required!']"
                v-model="daInvoiceCharge.gl_no"
                :error-messages="formErrors.gl_no"
                @input="delete formErrors.gl_no"
                showInvoiceAdditionalChargeForm
              />
            </v-col>
            <v-col cols="2" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="number"
                step=".01"
                :label="`Rate (${$globals.currency}) *`"
                required
                :clearable="false"
                readonly
                v-model="daInvoiceCharge.rate"
                :error-messages="formErrors.rate"
                @input="
                  delete formErrors.rate;
                  chargeDataChanged();
                "
              />
            </v-col>
            <v-col cols="2" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="number"
                step=".01"
                :label="`Tax (%)`"
                :clearable="false"
                required
                readonly
                :rules="[(value) => value >= 0 || 'Tax can not be less than 0']"
                v-model="daInvoiceCharge.tax_percentage"
                :error-messages="formErrors.tax_percentage"
                @input="delete formErrors.tax_percentage"
              />
            </v-col>
            <v-col cols="2" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="number"
                :label="`Tax Amount (${$globals.currency})`"
                :clearable="false"
                readonly
                :value="daInvoiceCharge.tax_amount"
              />
            </v-col>
            <v-col cols="2" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="number"
                :label="`Amount (${$globals.currency})`"
                :clearable="false"
                readonly
                :value="daInvoiceCharge.amount_with_tax"
              />
            </v-col>
            <v-col cols="2" class="Form-Columns-Dense">
              <MultiSelect
                :showAll="false"
                label="Credit Note Full Amount"
                v-model="daInvoiceCharge.full_amount_credit"
                itemValue="full_amount_credit"
                itemText="full_amount_credit"
                :items="['Yes', 'No']"
                hide-details
                @change="handleFullAmountChange"
              ></MultiSelect>
            </v-col>
            <v-col cols="2" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="number"
                :label="`Credit Amount (${$globals.currency})`"
                :clearable="false"
                :rules="[
                  (value) =>
                    (value != null && value != undefined) ||
                    'Credit Amount is required',
                  (val) => !!val || 'Credit Amount is Required!',
                ]"
                v-model="daInvoiceCharge.charge_credit_amount"
                :error-messages="formErrors.charge_credit_amount"
                :readonly="daInvoiceCharge.full_amount_credit === 'Yes'"
                @input="
                  delete formErrors.charge_credit_amount;
                  chargeDataChanged();
                "
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-end">
        <v-btn
          v-if="!isEditing"
          text
          depressed
          color="primary"
          @click="resetForm()"
        >
          {{ isEditing ? "Cancel" : "Reset" }}
        </v-btn>
        <v-btn
          :disabled="!chargesValid"
          depressed
          color="primary"
          @click="submit"
        >
          {{ isEditing ? "Update" : "Add" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
export default {
  components: {
    InputField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    selectedChargeData: {
      type: Object,
      default: {},
    },
    chargeId: {
      required: false,
    },
    daId: {
      required: false,
    },
    invoiceId: {
      required: false,
    },
    isReadOnly: {
      default: false,
    },
  },
  data() {
    return {
      allChargeList: [],
      formErrors: {},
      chargeData: {},
      daInvoiceCharge: {},
      chargesValid: false,
      newchargeId: null,
      previousRate: null,
      previousAmount: null,
    };
  },
  computed: {
    customerInvoiceFormDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    showAlert() {
      return (
        this.daInvoiceCharge.full_amount_credit &&
        this.daInvoiceCharge.full_amount_credit == "Yes" &&
        this.daInvoiceCharge.charge_type === "Freight"
      );
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.daInvoiceCharge = { ...this.selectedChargeData };
        this.previousRate = this.daInvoiceCharge.rate;
        this.previousAmount = this.daInvoiceCharge.amount_with_tax;
      } else {
        this.resetForm();
      }
    },
    "daInvoiceCharge.charge_credit_amount": function (newValue, oldValue) {
      if (newValue > 0) {
        this.daInvoiceCharge.charge_credit_amount = -newValue; // Ensure value is negative
      }
    },
  },
  methods: {
    handleFullAmountChange() {
      if (this.daInvoiceCharge.full_amount_credit === "Yes") {
        // Set charge_credit_amount to the negative value of amount_with_tax
        this.daInvoiceCharge.charge_credit_amount =
          -this.daInvoiceCharge.amount_with_tax;
      } else {
        // Handle other cases if needed
      }
    },
    chargeDataChanged() {
      let val =
        parseFloat(this.previousRate) +
        parseFloat(this.daInvoiceCharge.charge_credit_amount);
      if (val < 0) {
        this.formErrors.charge_credit_amount = `Credit Amount cannot be greater than ${this.previousRate}`;
      } else {
        delete this.formErrors.charge_credit_amount;
      }
    },

    resetForm() {
      if (this.$refs.customerInvoiceChargeForm) {
        this.daInvoiceCharge = {};
        this.$refs.customerInvoiceChargeForm.reset();
      }
      this.formErrors = {};
    },

    submit() {
      this.$emit("update:selectedChargeData", this.daInvoiceCharge);
      this.customerInvoiceFormDialog = false;
    },
  },
};
</script>

<style scoped>
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inline-checkbox {
  margin: 0;
  padding: 0;
}
</style>


